import React from 'react';
import logo from './logo.svg';
import './App.css';
import SplashPage from './components/SplashPage';
import Header from './components/Header';
import Footer from './components/Footer';
import ParticleBackground from './components/ParticleBackground';

function App() {
  return (
    <div className="App min-h-screen flex flex-col items-center justify-start bg-transparent">
      <ParticleBackground />
      <Header/>
      <SplashPage />
      <Footer/>
    </div>
  );
}

export default App;
