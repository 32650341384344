import { useState, useCallback } from 'react';
import { Button } from '@nextui-org/react';
import { Image } from '@nextui-org/react';
import { Link } from '@nextui-org/react';
import { Navbar } from '@nextui-org/react';
import { NavbarBrand } from '@nextui-org/react';
import { NavbarContent } from '@nextui-org/react';
import { NavbarItem } from '@nextui-org/react';
import Header from './Header';
import Typewriter, { TypewriterClass } from 'typewriter-effect';
import Particles from "react-particles";
import type { Container, Engine } from "tsparticles-engine";
import { loadFireflyPreset } from 'tsparticles-preset-firefly';
import { loadConfettiPreset } from 'tsparticles-preset-confetti';
import { loadFull } from "tsparticles";

const ParticleBackground = () => {
  const particlesInit = useCallback(async (engine:Engine) => {
    console.log("engine: ", engine);
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container: Container | undefined) => {
    await console.log(container);
  }, []);

  const particlesConfig={
    background: {
      color: {
        value: "#000",
      },
    },
    fpsLimit: 120,
    interactivity: {
      events: {
      //onClick: {
      //  enable: true,
      //  mode: "push",
      //},
      //onHover: {
      //  enable: true,
      //  mode: "repulse",
      //},
        resize: true,
      },
      modes: {
        push: {
          quantity: 4,
        },
        repulse: {
          distance: 200,
          duration: 0.4,
        },
      },
    },
    particles: {
      color: {
        value: "#e893a3",
      },
      links: {
        color: "#e893a3",
        distance: 150,
        enable: true,
        opacity: 0.5,
        width: 1,
      },
      move: {
        //direction: "none",
        enable: true,
      //outModes: {
      //  //default: "bounce",
      //},
        random: false,
        speed: 1.5,
        straight: false,
      },
      number: {
        density: {
          enable: true,
          area: 800,
        },
        value: 80,
      },
      opacity: {
        value: 0.5,
      },
      shape: {
        type: "circle",
      },
      size: {
        value: { min: 1, max: 5 },
      },
    },
    detectRetina: true,
  }

  return (
    <Particles id="background-particles" className="-z-10" options={particlesConfig} init={particlesInit} loaded={particlesLoaded} />
  );
}

export default ParticleBackground;
