import { useState, useCallback } from 'react';
import { Button } from '@nextui-org/react';
import { Image } from '@nextui-org/react';
import { Link } from '@nextui-org/react';
import { Navbar } from '@nextui-org/react';
import { NavbarBrand } from '@nextui-org/react';
import { NavbarContent } from '@nextui-org/react';
import { NavbarItem } from '@nextui-org/react';
import Header from './Header';
import Typewriter, { TypewriterClass } from 'typewriter-effect';
import Particles from "react-particles";
import type { Container, Engine } from "tsparticles-engine";
import { loadFireflyPreset } from 'tsparticles-preset-firefly';
import { loadConfettiPreset } from 'tsparticles-preset-confetti';
import { loadFull } from "tsparticles";


const TypeCommandsAnimation = () => {
  const [explosionCount, setExplosionCount] = useState<Number>(0);
  const particlesInit = useCallback(async (engine:Engine) => {
    console.log(explosionCount);
    console.log("engine: ", engine);
    //await loadFull(engine);
    await loadConfettiPreset(engine);
  }, [explosionCount]);

  const particlesLoaded = useCallback(async (container: Container | undefined) => {
    await console.log(container);
  }, []);

  const particlesConfig={
    preset: "confetti",
//  background: {
//    color: {
//      value: "#0d47a1",
//    },
//  },
//  fpsLimit: 120,
//  interactivity: {
//    events: {
//      onClick: {
//        enable: true,
//        mode: "push",
//      },
//      onHover: {
//        enable: true,
//        mode: "repulse",
//      },
//      resize: true,
//    },
//    modes: {
//      push: {
//        quantity: 4,
//      },
//      repulse: {
//        distance: 200,
//        duration: 0.4,
//      },
//    },
//  },
//  particles: {
//    color: {
//      value: "#ffffff",
//    },
//    links: {
//      color: "#ffffff",
//      distance: 150,
//      enable: true,
//      opacity: 0.5,
//      width: 1,
//    },
//    move: {
//      //direction: "none",
//      enable: true,
//    //outModes: {
//    //  //default: "bounce",
//    //},
//      random: false,
//      speed: 6,
//      straight: false,
//    },
//    number: {
//      density: {
//        enable: true,
//        area: 800,
//      },
//      value: 80,
//    },
//    opacity: {
//      value: 0.5,
//    },
//    shape: {
//      type: "circle",
//    },
//    size: {
//      value: { min: 1, max: 5 },
//    },
//  },
//  detectRetina: true,
  }

  return (
    <>
      <Typewriter
        options={{
          //cursor: '🔫'
          devMode: true
        }}
        onInit={async (typewriter) => {
          await typewriter.typeString('/snipe 0.2')
            .pauseFor(700)
            .callFunction((obj:any) => {
              console.log("explosionCount: ", explosionCount);
              setExplosionCount(1);
              console.log("explosionCount: ", explosionCount);
              obj.elements.container.firstElementChild!.innerHTML = '';
              typewriter.stop();
            })
            .start();

          await typewriter
            .typeString('/sell 20% for 0.5')
            .pauseFor(700)
            .callFunction(({ elements: { container } }) => {
              console.log("explosionCount: ", explosionCount);
              setExplosionCount(2);
              console.log("explosionCount: ", explosionCount);
              container.firstElementChild!.innerHTML = '';
              typewriter.stop();
            }).start();

          await typewriter
            .typeString('/sell 50% for 21')
            .pauseFor(700)
            .callFunction((obj:any) => {
              setExplosionCount(3);
              obj.elements.container.firstElementChild!.innerHTML = '';
              typewriter.stop();
            }).start();

          let string = '?...';
          await typewriter
            .typeString(string)
            .pauseFor(1200)
            .deleteChars(string.length)
            .start();

          string = 'Profit';
          await typewriter
            .typeString(string)
            .pauseFor(1000)
            .deleteChars(string.length)
            .typeString('Bandit Bunny')
            .start();
        }}
      />
      {/*explosionCount != 0 &&
        <Particles options={particlesConfig} init={particlesInit} loaded={particlesLoaded} />
        */}
    </>
  );
}

export default TypeCommandsAnimation;
