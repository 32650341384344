import { useState, useCallback } from 'react';
import { Button } from '@nextui-org/react';
import { Image } from '@nextui-org/react';
import { Link } from '@nextui-org/react';
import { Navbar } from '@nextui-org/react';
import { NavbarBrand } from '@nextui-org/react';
import { NavbarContent } from '@nextui-org/react';
import { NavbarItem } from '@nextui-org/react';
import Header from './Header';
import TypeCommandsAnimation from './TypeCommandsAnimation';


const SplashPage = () => {
  return (
    <div className="w-full flex-grow flex flex-col space-around items-center justify-center bg-transparent">
      <div className="grid place-items-center max-w-xs sm:max-w-lg w-full pt-10">
        <Image
          isZoomed
          width={200}
          alt="Bandit Bunny"
          src="https://cdn.discordapp.com/attachments/809507717228003398/1170423114853789916/DALLE_2023-11-04_17.56.10_-_A_minimalist_silhouette_logo_design_featuring_a_pink_bunny_in_mid-stride_carrying_a_sack_over_its_ba.png?ex=6558fc77&is=65468777&hm=cda53f99f8089cc72df9ccd21cb993f58a64811fe8a7e42eae71cf7b3c9238e8&"
        />
      </div>
      {/*<h1 className="text-3xl text-center my-5">Sniper</h1>*/}
      <TypeCommandsAnimation />

      {/*
      <Button onClick={() => window.open('https://sniper-webapp.vercel.app')} color="success" size="lg" className="mt-10">
          Enter App
      </Button>
        */}
      <Button onClick={
        () => window.open('https://discord.com/api/oauth2/authorize?client_id=1150500876457357312&permissions=534723749952&scope=bot')
        } size="lg" className="mt-10 bg-red-400 hover:bg-red-300">
        Invite Bot To Server
      </Button>
    </div>
  );
}

export default SplashPage;
