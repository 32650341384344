"use client";

import { Link } from '@nextui-org/react';
import { Icon } from '@iconify/react';
import React from 'react';

const Footer: React.FC = () => (
  <footer className="w-screen px-6 py-3 bg-primary-500 dark:bg-gray-800 dark:text-white">
    <ul className="flex flex-row justify-center space-x-4">
      <li>
        <Link href="https://twitter.com" className="text-red-300 hover:text-red-400">
          <Icon icon="pajamas:x" />
        </Link>
      </li>
      <li>
        <Link href="https://discord.com" className="text-red-300 hover:text-red-400">
          <Icon icon="line-md:discord" />
        </Link>
      </li>
      <li>
        <Link href="https://telegram.org" className="text-red-300 hover:text-red-400">
          <Icon icon="line-md:telegram" />
        </Link>
      </li>
      <li>
        <Link href="https://github.com" className="text-red-300 hover:text-red-400">
          <Icon icon="line-md:github" />
        </Link>
      </li>
      <li>
        <Link href="https://sniper-3.gitbook.io/product-docs/" className="text-red-300 hover:text-red-400">
          <Icon icon="simple-icons:gitbook" />
        </Link>
      </li>
    </ul>
  </footer>
);

export default Footer;
