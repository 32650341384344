"use client";
import { Button } from '@nextui-org/react';
import { Image } from '@nextui-org/react';
import { Link } from '@nextui-org/react';
import { Navbar } from '@nextui-org/react';
import { NavbarBrand } from '@nextui-org/react';
import { NavbarContent } from '@nextui-org/react';
import { NavbarItem } from '@nextui-org/react';
import { Icon } from '@iconify/react';

const Header = () => {
  return (
    <Navbar className="w-screen px-6 py-1 bg-primary-500 dark:bg-gray-800 dark:text-white">
      <NavbarBrand>
        <Image
          isZoomed
          width={80}
          alt="Bandit Bunny"
          src="https://cdn.discordapp.com/attachments/809507717228003398/1170423114853789916/DALLE_2023-11-04_17.56.10_-_A_minimalist_silhouette_logo_design_featuring_a_pink_bunny_in_mid-stride_carrying_a_sack_over_its_ba.png?ex=6558fc77&is=65468777&hm=cda53f99f8089cc72df9ccd21cb993f58a64811fe8a7e42eae71cf7b3c9238e8&"
        />
 
        <p className="font-semibold ml-2 text-red-300">Bandit Bunny</p>
      </NavbarBrand>
      <NavbarContent className="flex-grow items-center pr-6" justify="end">
          <NavbarItem>
              <Link href="https://sniper-3.gitbook.io/product-docs/" target="_blank" className="text-red-300 hover:text-red-400 transition duration-300 ease-in-out">
                  Docs
              </Link>
          </NavbarItem>
          {/*
          <NavbarItem>
            <Link href="#" className="hover:text-success transition duration-300 ease-in-out">
                Tokenomics
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link href="#" className="hover:text-success transition duration-300 ease-in-out">
                Discord Bot
            </Link>
          </NavbarItem>
          */}
      </NavbarContent>
    </Navbar>
  );
}

export default Header;
